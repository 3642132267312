import React, { useState, useRef, useEffect, useCallback } from "react";
import { Link, NavLink } from "react-router-dom";

const DropMenu = (props) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const { title, options } = props;

  const dropMenu = useRef();


  const toggleDropdown = async () => {

    await setDropdownOpen(true)
    
    document.querySelector(".droppingNavLink").classList.add('slideOver')
    
    document.querySelector('.dropdown-menu').classList.add('fadeIn')

  };

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (
        isDropdownOpen &&
        dropMenu.current &&
        !dropMenu.current.contains(e.target)
      ) {

        setDropdownOpen(false);

        document.querySelector('.droppingNavLink').classList.remove('slideOver')

        const fadeOption = document.querySelector(".dropdown-menu");
        console.log(fadeOption)
        fadeOption ? fadeOption.classList.remove("fadeIn") :  console.log('not Found')
        
        
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
  }, [isDropdownOpen]);

  return (
    <Link className='navLink droppingNavLink'>
      <div className='tab-header' onClick={toggleDropdown}>
        <span>{title}</span>
      </div>

      {isDropdownOpen && (
        <div ref={dropMenu} className='dropdown-menu' id="dropdown-menu">
          {options.map((option, idx) => (
            <Link className='dropdown-option' key={idx} to={`/services?selectedService=${option}`}>
              {option}
            </Link>
            
          ))}
        </div>
      )}
    </Link>
  );
};

export default DropMenu;
