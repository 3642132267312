
import heroLogo from '../assets/logo/THE-FINAL-LOGO-REVISION.png'


export default function Hero (){
    return (
        <div className="hero">
            <img src={heroLogo} alt='Revive Painting Company Logo' />
        </div>
    )
}

