
import {Link} from 'react-router-dom';

export default function Services () {


    return (
        <div className="servicesContainer">
            {/* <h3>
               Services 
            </h3> */}
            <div className="servicesGrid">
                <Link to="/services?selectedService=Painting" className="serviceCard interior-painting">
                    <p>Interior</p>
                </Link >

                <Link to="/services?selectedService=Painting"  className="serviceCard exterior-painting">
                    <p>Exterior</p>
                </Link>

                <Link to="/services?selectedService=Flooring"  className="serviceCard flooring">
                    <p>Flooring</p>
                </Link>

                <Link to="/services?selectedService=Gutters"  className="serviceCard gutters">
                    <p>Gutters</p>
                </Link>

                <Link to="/services?selectedService=Cabinets"  className="serviceCard cabinets">
                    <p>Cabinets</p>
                </Link>

                <Link to="/services?selectedService=Kitchen And Bath"  className="serviceCard kitchenBath">
                    <p>Kitchen/Bath</p>
                </Link>
            </div>
        </div>
    )
}