import { send } from "@emailjs/browser";

import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";

import './contact.css'



// Font Awesome Icons
import {
  faFacebook,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

import { faPhone, faEnvelope} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const phoneIcon = (
  <FontAwesomeIcon className='contact-page-icon' icon={faPhone} />
);
const envelopeIcon = (
  <FontAwesomeIcon className='contact-page-icon' icon={faEnvelope} />
);

const facebookIcon = (
  <FontAwesomeIcon className='contact-hero-icon' icon={faFacebook} />
);
const instaIcon = (
  <FontAwesomeIcon className='contact-hero-icon' icon={faInstagram} />
);
const linkedIcon = (
  <FontAwesomeIcon className='contact-hero-icon' icon={faLinkedin} />
);

const ContactPage = () => {
  const EMAIL_SERVICE_KEY = process.env.REACT_APP_EMAIL_SERVICE_KEY;
  const EMAIL_TEMP = process.env.REACT_APP_EMAIL_TEMP;
  const EMAIL_PUBLIC_KEY = process.env.REACT_APP_EMAIL_PUBLIC_KEY;

  // form validation schema with yup

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const formSchema = Yup.object({
    name: Yup.string()
      .min(3, "Name must be atleast 3 characters long.")
      .required("Please enter your First and Last name"),
    email: Yup.string()
      .email("Please enter a valid email address.")
      .required("Please enter a valid email address."),
    number: Yup.string()
      .matches(phoneRegExp, "Please enter a valid phone number")
      .required("Please enter a valid phone number"),
    zipcode: Yup.number()
      .typeError("Zipcode must be a number.")
      .test(
        "len",
        "Zip code must be 5 digits",
        (val) => val.toString().length === 5
      )
      .required("Please enter a valid zipcode."),
    serviceDetails: Yup.string()
      .min(4, "The description needs to be atleast 4 characters long.")
      .required("We need a short description of what you need help with."),
  });

  const initialFormState = {
    name: "",
    email: "",
    number: "",
    zipcode: "",
    serviceDetails: "",
  };

  const sendEmail = (values) => {
    send(EMAIL_SERVICE_KEY, EMAIL_TEMP, values, EMAIL_PUBLIC_KEY)
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  };

  const renderError = (message) => <p className='contact-page-error'>{message}</p>;

  return (
    <div className='contact-page-container'>
    
    <div className='contact-page-hero'>
      <div className='contact-form-intro'>
        <h1>Get in Touch</h1>
        <p className='contact-page-hero-description'>
          <i>Reach out to one of our professionals today.</i>
        </p>

        <div className='contact-page-form-links'>
        
        <div className='form-link-container'>
          <a className="contact-page-hero-icons" href='https://m.facebook.com/profile.php/?id=61556198105698&name=xhp_nt__fb__action__open_user)'>
            {facebookIcon}
          </a>
        </div>

        <div className='form-link-container'>
          <a className="contact-page-hero-icons" href='https://www.linkedin.com/company/revive-painting-company/about/'>{linkedIcon}</a>
        </div>

        <div className='form-link-container'>
          <a className="contact-page-hero-icons" href='https://www.instagram.com/revivepaintco/'>{instaIcon}</a>
        </div>

        </div>
      </div>
      <div className='contact-page-form-container'>
        <Formik
          initialValues={initialFormState}
          validationSchema={formSchema}
          onSubmit={async (values, { resetForm }) => {
            sendEmail(values);
            resetForm();
          }}
        >
          <Form className='contact-page-form' id='contact-page-form'>
            <div className='contact-page-inputs'>
              <div className='contact-page-fieldset name'>
                <Field
                  className='contact-page-form-input'
                  id='name'
                  name='name'
                  type='text'
                  placeholder='Full Name'
                />
                <ErrorMessage name='name' render={renderError} />
              </div>

              <div className='contact-page-fieldset email'>
                <Field
                  className='contact-page-form-input'
                  id='email'
                  name='email'
                  type='text'
                  placeholder='Email Address'
                />
                <ErrorMessage name='email' render={renderError} />
              </div>

              <div className='contact-page-fieldset number'>
                <Field
                  className='contact-page-form-input'
                  id='number'
                  name='number'
                  type='tel'
                  placeholder='Phone Number'
                />
                <ErrorMessage name='number' render={renderError} />
              </div>

              <div className='contact-page-fieldset zipcode'>
                <Field
                  className='contact-page-form-input'
                  id='zipcode'
                  name='zipcode'
                  type='text'
                  placeholder='Zip Code'
                />
                <ErrorMessage name='zipcode' render={renderError} />
              </div>
              <div className='contact-page-fieldset message'>
              <Field
                className='contact-page-form-input'
                as='textarea'
                id='contact-page-service-details'
                name='serviceDetails'
                cols='10'
                rows='3'
                placeholder='Service Description'
              />
              <ErrorMessage name='serviceDetails' render={renderError} />
            </div>
            </div>
            
            <button type='submit' className='contact-page-submit-button'>
              Submit Message
            </button>
          </Form>
        </Formik>
      </div>
    </div>
    
    <div className='contact-page-main'>
          <p className='main-section-title'>Prefer to reach out directly?</p>
          <p className='main-section-description'>Our team of dedicated professionals is committed to providing exemplary service tailored to your needs. For further assistance, please don't hesitate to contact us. </p>

          <div className="contact-page-card-container">


                <a className='contact-page-card-wrap' href='mailto:office@revivepaintingatlanta.com'>
                <div className='contact-page-card'>
                {envelopeIcon}
                <p className="contact-page-card-title">Email Us</p>
                <p className='contact-page-card-description'>office@revivepaintingatlanta.com</p>
                </div>
                </a>


                <a className='contact-page-card-wrap' href="tel:+16789938149">
                <div  className='contact-page-card'>
                {phoneIcon}
                <p className="contact-page-card-title">Call Us</p>
                <p className='contact-page-card-description'>{`(678) 993-8149`}</p>

                </div>
                </a>
          </div>
    </div>
    
    </div>
  );
};

export default ContactPage;
