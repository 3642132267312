import { Route, Routes } from "react-router-dom";

import "./App.css";

// Components
import Header from "./components/Header";
import Footer from "./components/Footer";
import Nav from "./components/Nav";

// Pages
import Home from "./pages/Home/Home.js";
import About from "./pages/About/About.js";
import ServicesPage from './pages/Services/ServicesPage.js'
import Gallery from './pages/Gallery/Gallery.js'
import ContactPage from "./pages/Contact/Contact.js";


function App() {
  return (
    <div className='App'>
      <Header />
      <Nav />

      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/services' element={<ServicesPage />} />
        <Route path='/gallery' element={<Gallery />} />
        <Route path='/contact' element={<ContactPage />} />
        <Route path='/help' element={<div>This is the help FAQ page</div>} />
      </Routes>

      <Footer />
    </div>
  );
}

export default App;
