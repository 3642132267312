import { Link } from "react-router-dom";

import MainLogoPNG from '../assets/logo/newest-new-absolute-logo.jpeg'
const Header = () => {
  return (
    <div className='header'>
      <Link to="/" className='header-logo'>
        <img className="mainLogo" src={MainLogoPNG} alt="Revive Painting Atlanta" />

        {/* <p className="textLogo">Revive Painting Atlanta</p> */}
      </Link>
      <div className='callToAction'>
        <div className='callToday'>
          
          <a href='tel:+16789938149'>
          Call Today!
          <br/>
          678-993-8149
          </a>
        </div>

        <Link to="/contact" className='getEstimate'>Get Free Estimate</Link>
      </div>
    </div>
  );
};

export default Header;
