import { send } from "@emailjs/browser";

import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";

// Font Awesome Icons
import {
  faFacebook,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

import { faSquare } from "@fortawesome/free-regular-svg-icons";

import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const squareBulletPoint = (
  <FontAwesomeIcon className='bulletPoint' icon={faSquare} />
);

const phoneIcon = (
  <FontAwesomeIcon className='contact-sidebar-icon' icon={faPhone} />
);

const envelopeIcon = (
  <FontAwesomeIcon className='contact-sidebar-icon' icon={faEnvelope} />
);

const facebookIcon = (
  <FontAwesomeIcon className='contact-social-icon' icon={faFacebook} />
);
const instaIcon = (
  <FontAwesomeIcon className='contact-social-icon' icon={faInstagram} />
);
const linkedIcon = (
  <FontAwesomeIcon className='contact-social-icon' icon={faLinkedin} />
);

const ContactForm = (props) => {
  const EMAIL_SERVICE_KEY = process.env.REACT_APP_EMAIL_SERVICE_KEY;
  const EMAIL_TEMP = process.env.REACT_APP_EMAIL_TEMP;
  const EMAIL_PUBLIC_KEY = process.env.REACT_APP_EMAIL_PUBLIC_KEY;

  // form validation schema with yup

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const formSchema = Yup.object({
    name: Yup.string()
      .min(3, "Name must be atleast 3 characters long.")
      .required("Please enter your First and Last name"),
    email: Yup.string()
      .email("Please enter a valid email address.")
      .required("Please enter a valid email address."),
    number: Yup.string()
      .matches(phoneRegExp, "Please enter a valid phone number")
      .required("Please enter a valid phone number"),
    zipcode: Yup.number()
      .typeError("Zipcode must be a number.")
      .test(
        "len",
        "Zip code must be 5 digits",
        (val) => val.toString().length === 5
      )
      .required("Please enter a valid zipcode."),
    serviceDetails: Yup.string()
      .min(4, "The description needs to be atleast 4 characters long.")
      .required("We need a short description of what you need help with."),
  });

  const initialFormState = {
    name: "",
    email: "",
    number: "",
    zipcode: "",
    serviceDetails: "",
  };

  const sendEmail = (values) => {
    send(EMAIL_SERVICE_KEY, EMAIL_TEMP, values, EMAIL_PUBLIC_KEY)
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  };

  const renderError = (message) => <p className='error'>{message}</p>;

  return (
    <div className='contact-form-container'>
      <div className='contact-form-sidepanel'>
        <div className='company-information'>
          {/* <li className='contact-information-title'>
            {squareBulletPoint}
            Some weird title thing idk what it does or what it's supposed to be.
          </li> */}

          <div className='info-section'>
            <p className='info-section-title'>{envelopeIcon}EMAIL</p>
            <a
              href='mailto:office@revivepaintingatlanta.com'
              className='info-section-data'
            >
              office@revivepaintingatlanta.com
            </a>
          </div>

          <div className='info-section'>
            <p className='info-section-title'>{phoneIcon}PHONE</p>
            <a href='tel:+16789938149' className='info-section-data'>
              678-993-8149
            </a>
          </div>
        </div>

        <div className='sidepanel-social-links'>
          <div className='social-link-container'>
            <a href='https://www.instagram.com/revivepaintco/'>{instaIcon}</a>
          </div>

          <div className='social-link-container'>
            <a href='https://m.facebook.com/profile.php/?id=61556198105698&name=xhp_nt__fb__action__open_user)'>
              {facebookIcon}
            </a>
          </div>

          <div className='social-link-container'>
            <a href='https://www.linkedin.com/company/revive-painting-company/about/'>{linkedIcon}</a>
          </div>
        </div>
      </div>

      <Formik
        initialValues={initialFormState}
        validationSchema={formSchema}
        onSubmit={async (values, { resetForm }) => {
          sendEmail(values);
          resetForm();
        }}
      >
        <Form className='contactForm' id='contactForm'>
          <div className='customer-detail-inputs'>
            <div className='fieldset name'>
              <Field
                className='contactInput'
                id='name'
                name='name'
                type='text'
                placeholder='Full Name'
              />
              <ErrorMessage name='name' render={renderError} />
            </div>

            <div className='fieldset email'>
              <Field
                className='contactInput'
                id='email'
                name='email'
                type='text'
                placeholder='Email Address'
              />
              <ErrorMessage name='email' render={renderError} />
            </div>

            <div className='fieldset number'>
              <Field
                className='contactInput'
                id='number'
                name='number'
                type='tel'
                placeholder='Phone Number'
              />
              <ErrorMessage name='number' render={renderError} />
            </div>

            <div className='fieldset zipcode'>
              <Field
                className='contactInput'
                id='zipcode'
                name='zipcode'
                type='text'
                placeholder='Zip Code'
              />
              <ErrorMessage name='zipcode' render={renderError} />
            </div>
          </div>
          <div className='fieldset message'>
            <Field
              as='textarea'
              id='serviceDetails'
              name='serviceDetails'
              cols='10'
              rows='6'
              placeholder='Service Description'
            />
            <ErrorMessage name='serviceDetails' render={renderError} />
          </div>

          <button type='submit' className='submitFormButton'>
            Send
          </button>
        </Form>
      </Formik>
    </div>
  );
};

export default ContactForm;
