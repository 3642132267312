import { Link } from "react-router-dom";


import MainLogoBlack from '../assets/logo/png/logo-black.png'


// FontAwesome Icons
import { faFacebook, faInstagram, faLinkedin } from "@fortawesome/free-brands-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const facebookIcon = <FontAwesomeIcon className="footerIcon" icon={faFacebook} />
const instaIcon = <FontAwesomeIcon className="footerIcon" icon={faInstagram} />
const linkedIcon = <FontAwesomeIcon className="footerIcon" icon={faLinkedin} />

const Footer = () => {
    return (
        <footer>
            <div className="companyLinksContainer">
                {/* <Link className="companyLink" to="/">
                <h3 className="footerLogo">Revive Painting Atlanta</h3>
                </Link> */}
                <Link to='/contact' className="companyLink">
                    Help
                </Link>
            </div>

            <div className="socials">
                <a href="https://www.instagram.com/revivepaintco/">{instaIcon}</a>
                <a href="https://m.facebook.com/profile.php/?id=61556198105698&name=xhp_nt__fb__action__open_user)">{facebookIcon}</a>
                <a href="https://www.linkedin.com/company/revive-painting-company/about/">{linkedIcon}</a>
            </div>

            <Link to='/contact' className="contactLink">Contact</Link>
        </footer>
    )

}

export default Footer;