import { Link, NavLink } from "react-router-dom";
import DropMenu from "./DropMenu";


const servicesOptions = [
    'Painting',
    'Flooring',
    'Gutters',
    'Cabinets',
    'Kitchen And Bath'
]

const Nav = () => {

    return (
        <nav>
            <Link className="navLink" to="/about">About Us</Link>
            <DropMenu title="Services" options={servicesOptions} />
            <Link className="navLink" to="/gallery">Gallery</Link>
            <Link className="navLink" to='/contact'>Contact</Link>
        </nav>
    )
};

export default Nav;