
import Hero from '../../components/Hero';
import Services from '../../components/Services';
import ContactForm from '../../components/ContactForm';

import './home.css'

const Home = () => {
    return (
        <div className="homeContainer">
        <Hero />

        <div className="banner">
        <h3>Painting Company in Roswell, Georgia</h3>
        </div>
        
        <div className="aboutDescription">
            <p>
            Welcome to Revive Painting! We are a team of consistent and reliable employees who love bringing your spaces to life. Using passion and precision, we turn dull walls into vibrant canvases. Join us in the art of transformation, where every color and brushstroke reshapes your home.
            </p>
        </div>

        <Services />

        <div className='contact-form-section'>
        <p className="contact-form-section-title">Schedule a Free Estimate</p>
        <ContactForm />
        </div>


        </div>
    )
}

export default Home;