import {Link} from 'react-router-dom';

import '../Gallery/gallery.css'


const Gallery = () => {
    return (
        <div>
            <div className='gallery-hero'>
                <p className='gallery-hero-title'>Gallery</p>
                <Link to="/contact" className='gallery-page-hero-button'>Get Free Estimate</Link>
            </div>
            <div className='gallery-container'>
                <h1 style={{textAlign:"center"}}>Soon to come!</h1>
            </div>
        </div>
    )
}


export default Gallery