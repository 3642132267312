import { send } from "@emailjs/browser";

import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";




const MiniContactForm = () => {

  const EMAIL_SERVICE_KEY = process.env.REACT_APP_EMAIL_SERVICE_KEY;
  const EMAIL_TEMP = process.env.REACT_APP_EMAIL_TEMP;
  const EMAIL_PUBLIC_KEY = process.env.REACT_APP_EMAIL_PUBLIC_KEY;
  
    // form validation schema with yup
  
    const phoneRegExp =
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  
    const formSchema = Yup.object({
      name: Yup.string()
        .min(3, "Name must be atleast 3 characters long.")
        .required("Please enter your First and Last name"),
      email: Yup.string()
        .email("Please enter a valid email address.")
        .required("Please enter a valid email address."),
      number: Yup.string()
        .matches(phoneRegExp, "Please enter a valid phone number")
        .required("Please enter a valid phone number"),
      zipcode: Yup.number()
        .typeError("Zipcode must be a number.")
        .test(
          "len",
          "Zip code must be 5 digits",
          (val) => val.toString().length === 5
        )
        .required("Please enter a valid zipcode."),
      serviceDetails: Yup.string()
        .min(4, "The description needs to be atleast 4 characters long.")
        .required("We need a short description of what you need help with."),
    });
  
    const initialFormState = {
      name: "",
      email: "",
      number: "",
      zipcode: "",
      serviceDetails: "",
    };
  
    const sendEmail = (values) => {
      send(EMAIL_SERVICE_KEY, EMAIL_TEMP, values, EMAIL_PUBLIC_KEY)
        .then((res) => console.log(res))
        .catch((err) => console.log(err));
    };
  
    const renderError = (message) => <p className='error'>{message}</p>;


    return (
        <Formik
        initialValues={initialFormState}
        validationSchema={formSchema}
        onSubmit={async (values, { resetForm }) => {
          sendEmail(values);
          resetForm();
        }}
      >
        <Form className='mini-contact-form' id='contactForm'>
          <p className='mini-contact-form-title'>Contact Us</p>        
          <div className='mini-customer-detail-inputs'>
            <div className='mini-fieldset mini-name'>
              <Field
                className='mini-contactInput'
                id='name'
                name='name'
                type='text'
                placeholder='Full Name'
              />
              <ErrorMessage name='name' render={renderError} />
            </div>

            <div className='mini-fieldset mini-email'>
              <Field
                className='mini-contactInput'
                id='email'
                name='email'
                type='text'
                placeholder='Email Address'
              />
              <ErrorMessage name='email' render={renderError} />
            </div>

            <div className='mini-fieldset mini-number'>
              <Field
                className='mini-contactInput'
                id='number'
                name='number'
                type='tel'
                placeholder='Phone Number'
              />
              <ErrorMessage name='number' render={renderError} />
            </div>

            <div className='mini-fieldset mini-zipcode'>
              <Field
                className='mini-contactInput'
                id='zipcode'
                name='zipcode'
                type='text'
                placeholder='Zip Code'
              />
              <ErrorMessage name='zipcode' render={renderError} />
            </div>
          </div>
          <div className='mini-fieldset mini-message'>
            <Field
              as='textarea'
              id='serviceDetails'
              name='serviceDetails'
              cols='10'
              rows='6'
              placeholder='Service Description'
            />
            <ErrorMessage name='serviceDetails' render={renderError} />
          </div>

          <button type='submit' className='mini-submit-form-button'>
            Send
          </button>
        </Form>
      </Formik>
    )
}

export default MiniContactForm