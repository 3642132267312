import { useSearchParams, Link } from "react-router-dom";

// COMPONENTS
import MiniContactForm from '../../components/MiniContactForm';

// CSS
import "./servicesPage.css";




// IMAGE ASSETS
import paintingImage from "../../assets/reference-photos/room-shot-7.jpg"
import flooringImage from "../../assets/reference-photos/flooring-5.jpg"
import guttersImage from "../../assets/reference-photos/house-shot-1.jpg"
import cabinetsImage from "../../assets/reference-photos/kitchen-0.jpg"
import kitchenAndBathImage from "../../assets/reference-photos/bathroom-1.jpg"


const ServicesPage = () => {
  const servicesOptions = [
    "Painting",
    "Flooring",
    "Gutters",
    "Cabinets",
    "Kitchen and Bath",
  ];

  const serviceDescriptions = {
    painting : "Elevate your home's interior and exterior with our premier painting service. Whether refreshing your living space or enhancing your curb appeal, our skilled team delivers impeccable results. From meticulous surface preparation to the application of high-quality paints, we ensure a flawless finish that reflects your unique style. With expertise in both interior and exterior painting, we transform every corner of your home with precision and care. Trust us to revitalize your surroundings, inside and out, creating a vibrant and inviting atmosphere that you'll love to call your own. Experience the difference with our comprehensive painting service.",

    flooring: "Revitalize your home with our top-tier flooring service, expertly crafted to enhance both interior and exterior spaces. Our dedicated team meticulously installs a wide range of flooring materials, from luxurious hardwood to durable LVP, ensuring impeccable results that elevate your property's aesthetic appeal and functionality. We provide meticulous attention to detail and superior craftsmanship. With our comprehensive flooring solutions, your home will exude timeless elegance and lasting durability, creating a welcoming environment for years to come. Experience the transformative power of our flooring service and reimagine your living spaces today.",

    gutters: "Experience the ultimate peace of mind with our premier gutter service, designed to protect and enhance your home's exterior. Our expert team meticulously installs and maintains high-quality gutter systems, tailored to fit your property's specific needs. From seamless aluminum to durable PVC, we offer a range of materials to ensure optimal performance and longevity. With meticulous attention to detail and superior craftsmanship, we safeguard your home against water damage and erosion, ensuring proper drainage and preserving its structural integrity. Trust us to deliver reliable solutions that enhance both the functionality and aesthetics of your property's exterior. Enjoy worry-free maintenance and protection with our comprehensive gutter service, providing unparalleled peace of mind for your home.",

    cabinets: "Revamp your space with our top-tier cabinet painting service! Our skilled team customizes paint finishes for your cabinets, enhancing both style and function. Whether you prefer modern or classic, we offer various paint options to match your taste. With professional-grade techniques, expect flawless results that blend seamlessly with your decor. From kitchen to bathroom, we're committed to delivering quality craftsmanship and attention to detail.",

    kitchenandbath: "Our kitchen and bathroom painting services bring new energy into any space. We use top-quality paints to deliver a fresh, vibrant look in every room, as our experienced and enthusiastic team of professionals bring their unique touches to every project. We guarantee prompt service and satisfaction to all our customers. Call us today to get started!"

  }

  const serviceImages = {
    painting: paintingImage,

    flooring: flooringImage,

    gutters: guttersImage,

    cabinets: cabinetsImage,

    kitchenandbath: kitchenAndBathImage  
  }
  


  const [queryParams] = useSearchParams();
  let selectedService = queryParams.get("selectedService") ? queryParams.get("selectedService"): 'Painting';

  

  return (
    <div className='services-page-container'>
      <div className='services-hero'>
        <p className='services-hero-title'>Services</p>
        <Link to='/contact' className='services-page-hero-button'>Contact Us</Link>

      </div>

      <div className='service-display-section'>
        <div className='listed-services-container'>
          <div className='listed-services'>
            {servicesOptions.map((option, idx) => {
              return (
                <Link
                  className='listed-services-option'
                  key={idx}
                  to={`/services?selectedService=${option}`}
                >
                  {option}
                </Link>
              );
            })}
          </div>
        </div>
        
        <div className="border-selected-service-image"></div>
        <img
          src={serviceImages[selectedService.split(' ').join('').toLowerCase()]}
          alt={`example of ${selectedService}`}
        />
      </div>

      <div className="contact-and-description-container">
       
        <div className='selected-service-description-container'>
            <p className='selected-service-title'>{selectedService}</p>
            <p className='selected-service-description'>
            {
              serviceDescriptions[selectedService.split(' ').join('').toLowerCase()]
            }
            </p>
        </div>

        <div className="mini-contact-container">
          <MiniContactForm />
        </div>
      </div>

    </div>
  );
};

export default ServicesPage;
