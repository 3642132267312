import "./about.css";


import aboutUsPhoto from '../../assets/reference-photos/painting-wall-1.jpg'

import susanPhoto from '../../assets/SUSANPHOTOEVEN.jpg'
import jorgePhoto from '../../assets/JORGEPHOTOEVEN.jpg'

const About = () => {
  return (
    <section className='about-us-container'>
      <div className='about-hero'>
        <p className='about-hero-title'>About Us</p>
      </div>

      <div className='about-us-info-section'>
        <div className='info-section-description'>
          <p className='description-title'>Who We Are</p>
          <p className='description'>
          Revive Painting Company is more than just a painting company; we're artisans dedicated to revitalizing spaces with a brushstroke of excellence. As a new and dynamic player in the industry, we bring a fresh perspective and unparalleled skill to every project we undertake. With a commitment to quality craftsmanship and exceptional service, we breathe new life into homes and businesses across Atlanta. Trust Revive Painting Company to transform your vision into vibrant reality.
          </p>
        </div>
        <img className="useless-mobile-photo"
          src={aboutUsPhoto}
          alt='images of the company being the company'
        />
      </div>
      <h1 className='our-team-title'>Our Team</h1>
      <div className='our-team-section'>
        
        <div className='team-member-container'>
          <div className='portrait-container'>

            <img
              src={susanPhoto}
              alt='portrait of the owner/president'
            />
            <h3>Susan Hohla</h3>
            <p>Owner</p>
          </div>

          <div className='portrait-container'>
            <img
              src={jorgePhoto}
              alt='portrait of the owner/president'
            />
            
            <h3>Jorge Ruiz</h3>
            <p>President of Production</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
